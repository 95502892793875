import Label from "../atoms/Label";
import * as styles from './ConfigurationItem.module.css';
import flagStyles from '../molecules/FlagSelector.module.css';
import Inline from "../atoms/Inline";
import Circle from "../atoms/Circle";
import useAppStore from "../hooks/useAppStore";
import {useTranslation} from "react-i18next";
import flagsGroup from "../configurations/flagsGroup";

export default function ConfigurationItem({
  index,
  item,
  active = false,
  status = 'todo',
  disabled= false,
  onSelect
}) {

  const { t } = useTranslation();

  const {currentMaterials, currentGroup, details, textColor} = useAppStore(state => ({
    currentMaterials: state.currentMaterials,
    currentGroup: state.currentGroup,
    details: state.details,
    textColor: state.textColor,
  }));

  const getColor = () => {
    if(currentMaterials === null || currentMaterials.length === 0) return 'transparent';

    const currentMaterial = currentMaterials.find( material => material.group.id === item.id);
    return currentMaterial.configuration.color;
  };

  const getSize = () => {
    if(details.length === 0) return '';
    
    return details[0].size;
  };

  const getFlag = () => {
    
    if(details.length === 0) return '';
    if(details[0].flag === null) return '';
    
    return details[0].flag.toLowerCase();
  }
  
  let className = active ? styles.active : styles[status];
  if(disabled) className=`${className} ${styles.disabled}`;
  
  return (
    <div className={className} onClick={ () => { if(!disabled) onSelect(item); } }>
      <div style={{width: '100%'}} >
        <Inline align="space-between" verticalAlign="center" >
          <Label size="normal" spacing="medium">
            {index}. {t(`material.${item.name}`.toLowerCase())}
          </Label>
          {item.selectorType === 'color' && (
            <Circle color={ status === itemStatus.done ? getColor() : '#D8D8D8'}/>
          )}
          {item.selectorType === 'size' && (
            <Label weight="medium" color="#979797">{getSize()}</Label>
          )}
          {item.selectorType === 'flag' && details.length > 0 && (
            <div className={`${flagStyles.flag} ${flagStyles[getFlag()]}`} />
          )}
          {item.selectorType === 'text' && textColor !== null && details.length > 0 && (
            <Label size='small' weight="weak" color="#D8D8D8">{details[0].text}</Label>
          )}
          {item.selectorType === 'quantity' && details.length > 0 && (
            <Label size='small' weight="weak" color="#D8D8D8">{details.length}</Label>
          )}
        </Inline>
      </div>
    </div>
  );
};

export const itemStatus = {
  todo: 'todo',
  done: 'done',
};
