import * as styles from "../templates/MainTemplate.module.css";
import Stack from "../atoms/Stack";
import Inline from "../atoms/Inline";
import Label from "../atoms/Label";
import Button from "../atoms/Button";
import TextIconButton from "../molecules/TextIconButton";
import VariantSelector from "../templates/VariantSelector";
import Navigation from "./Navigation";
import useAppStore from "../hooks/useAppStore";
import {useContext, useEffect} from "react";
import EmersyaViewerContext from "../contexts/EmersyaViewerContext";
import {useTranslation} from "react-i18next";
import modes from "../configurations/modes";

export default function Footer({
  ...props
}) {

  const { t } = useTranslation();

  const {
    showRecap, modelConfiguration, currentGroup, currentMaterials,
    applyingMaterials, productId, price, details,
    mode, textColor, modelId, flagOutlineColor
  } = useAppStore( (state) => ({
    showRecap: state.showRecap,
    modelConfiguration: state.modelConfiguration,
    currentGroup: state.currentGroup,
    currentMaterials: state.currentMaterials,
    applyingMaterials: state.applyingMaterials,
    productId: state.productId,
    price: state.price,
    details: state.details,
    mode: state.mode,
    textColor: state.textColor,
    flagOutlineColor: state.flagOutlineColor,
    modelId: state.modelId,
  }));

  const total = modelConfiguration.configurableGroups.length;
  
  const emersyaViewerContext = useContext(EmersyaViewerContext);
  const viewer = emersyaViewerContext.viewer;

  const currentIndex = currentGroup === null
    ? 0
    : modelConfiguration.configurableGroups.findIndex(group => group.name === currentGroup.name);
  
  const currentSize =  details.length > 0 ? details[0].size : null;
  
  const screenshot = () => {
    viewer.getScreenshot({
      width: 512,
      height: 512,
      transparentBackground: true,
      camera: modelConfiguration.screenshotCamera
    })
      .then(data => {
        const onPurchase = new CustomEvent('onPurchase', {
          detail: {
            image: data,
            modelId,
            modelName: modelConfiguration.name,
            productId,
            size: currentSize,
            price,
            configuration: currentMaterials.map(material => ({
              groupName: material.group.name,
              configuration: {
                name: material.configuration.name,
              },
              code: material.configuration.code,
            })),
            textColor,
            flagOutlineColor,
            details
          }
        });
        const target = window.DMTConfigurator
          ? document.getElementById(window.DMTConfigurator.elementId)
          : window;
        target.dispatchEvent(onPurchase);
        console.log('onPurchase event dispatched');
        console.log(onPurchase);
      })
      .error(err => console.error(err));
  }

  useEffect(function (){
    if(! showRecap) return;
    if(viewer === null) return;

    viewer.play().then( data => console.log('play started', data) );
  }, [viewer, showRecap]);
  
  
  if(showRecap) {
    return (
      <div className={styles.purchaseFooter}>
        <Stack valign="space-between" halign="center">
          <Inline>
            <Label size="large" weight="bold">{modelConfiguration.name}</Label>
            {mode === modes.B2C && (
              <>
                <Label size="large" weight="normal" color='#ff7500'>{price}</Label>
                <Label weight="normal" color="#979797">|</Label>
                <Label size="medium" weight="normal" color="#979797" style={{textTransform: 'capitalize'}}>
                  {t('material.taglia')}: {currentSize}
                </Label>
              </>
            )}
          </Inline>
          {mode === modes.B2B && (
            <Label color="#D8D8D8">{details.length} {t('text.pairs').toUpperCase()}</Label>
          )}
          <Button color="#fff" primary={false} purchase={true} onClick={screenshot}>
            {t('button.purchase')}
          </Button>
          <TextIconButton icon="virtualTour" text="VIRTUAL TRY ON" onClick={() => viewer.startAr({startInNewPage : 'newTab'}).then(data => console.log(data))}/>
        </Stack>
      </div>
    );
  }

  return (
      <Stack valign="center" halign="center">
        <Inline>
          <Label
            size="medium"
            weight="bold"
            spacing="medium"
            style={{textTransform: 'uppercase'}}
          >
            {currentGroup && t(`material.${currentGroup.name.toLowerCase()}`)}
          </Label>
          <Label
            size="medium"
            weight="medium"
            color="#979797"
          >
            {currentIndex + 1} | {total}
          </Label>
        </Inline>

        <div className={styles.variantSelector}
             style={{opacity: applyingMaterials ? .3 : null}}>
          <VariantSelector />
        </div>
        <Navigation />
      </Stack>
  );
}
